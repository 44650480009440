<script setup lang="ts">
import markdownit from 'markdown-it';
// @ts-ignore
import markdownItMark from 'markdown-it-mark';

withDefaults(defineProps<{
  allowHtml?: boolean;
  allowLists?: boolean;
  allowTextFormatting?: boolean;
  content: string;
  hasPurpleBackground?: boolean;
  inline?: boolean;
}>(), { allowTextFormatting: false,hasPurpleBackground: false,inline: true, })

const formattedContent = computed(() => {
  const listItemIcons = {
    check: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check ${__props.hasPurpleBackground
      ? '!text-white'
      : ''}"><path d="M20 6 9 17l-5-5"/></svg>`,
  };

  const md = markdownit('zero', {
    html: __props.allowHtml,
    linkify: true,
  })
    .use(markdownItMark)
    .enable(__props.allowLists
      ? [ 'list' ]
      : [])
    .enable(__props.allowTextFormatting
      ? [
        'emphasis',
        'link',
      ]
      : []);

  md.renderer.rules.list_item_open = () => `<li>${listItemIcons.check}<span>`;
  md.renderer.rules.list_item_close = () => '</span></li>';

  return md[__props.inline
    ? 'renderInline'
    : 'render'](__props.content);
});
</script>

<template>
  <span v-html="formattedContent" />
</template>

<style lang="scss" scoped>
:deep(ul) {
  li {
    svg {
      @apply size-5 text-purple shrink-0;
    }

    @apply flex items-center gap-2.5 justify-start;
  }

  @apply m-0 list-none space-y-4;
}

:deep(p) {
  @apply mt-0 mb-4 align-middle inline-block;
}

:deep(a) {
  @apply underline;
}
</style>
